<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text class="d-flex">
      <v-form
        ref="form"
        class="multi-col-validation mt-6"
      >
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-row class="justify-center align-center">
              <v-col
                cols="12"
                md="7"
                class="text-center"
              >
                <v-avatar
                  rounded
                  size="120"
                  class="mx-auto"
                  width="200"
                  height="200"
                  color="primary"
                >
                  <v-img
                    v-if="imageData || dataComputed.logo"
                    :src="imageData ? urlImage : dataComputed.logo"
                  ></v-img>
                  <span
                    v-else
                    class="font-weight-medium"
                  >{{ t('facilities.logo') }}</span>
                </v-avatar>
              </v-col>

              <v-col
                cols="12"
                md="5"
              >
                <!-- upload photo -->
                <div class="my-auto">
                  <v-file-input
                    v-model="imageData"
                    accept="image/png, image/jpg"
                    :placeholder="t('facilities.select_avatar')"
                    :label="t('facilities.logo')"
                    :prepend-icon="icons.mdiCamera"
                    outlined
                    dense
                    hide-details
                    @change="onFileSelected($event)"
                  />
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            md="6"
            cols="12"
            class="d-flex align-center"
          >
            <v-select
              v-model="dataComputed.facilities_ids"
              :label="t('facilities.facilities')"
              :items="facilitiesOptions"
              item-text="name"
              item-value="id"
              :menu-props="{ maxHeight: '400' }"
              multiple
              persistent-hint
              dense
              outlined
              class="mb-0"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            >
              <template #selection="data">
                <v-chip
                  v-bind="data.attrs"
                  label
                  dense
                  :input-value="data.selected"
                  @click="data.select"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-select>
          </v-col>

          <v-col
            md="4"
            cols="12"
          >
            <v-text-field
              v-model="dataComputed.name"
              :label="t('users.name')"
              dense
              outlined
              :readonly="option===2"
              :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="dataComputed.email"
              :label="t('users.email')"
              dense
              outlined
              :readonly="option===2"
              :rules="[required,emailValidator]"
            >
            </v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <div class="d-flex justify-space-between align-items-start">
              <v-select
                v-model="phoneCode"
                dense
                outlined
                :items="phoneCodeOptions"
                item-text="text"
                item-value="value"
                style="
                  width: 35%;
                "
                :append-icon="option===2 ? null : icons.mdiMenuDown"
                :readonly="option===2"
                hide-details
                :rules="[required]"
                @change="() => phoneNumber = null"
              >
                <template v-slot:item="{ item }">
                  <img
                    :src="item.image"
                    :alt="item.value"
                    width="20"
                    height="auto"
                    class="mr-2"
                  >
                  {{ item.text }}
                </template>
              </v-select>

              <v-text-field
                v-model="phoneNumber"
                :label="t('users.phone')"
                dense
                outlined
                class="pl-2"
                :disabled="!phoneCode"
                :readonly="option===2"
                :rules="[required, validatePhone]"
              ></v-text-field>
            </div>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="dataComputed.billing_cycle"
              dense
              outlined
              :label="t('facilities.billing_cycle')"
              :items="billingCycleOptions"
              item-text="text"
              item-value="value"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              v-model.number="dataComputed.comission"
              :label="t('video_details.comission')"
              dense
              outlined
              prefix="%"
              :readonly="option===2"
              :rules="[required, between(dataComputed.comission, 0, 100)]"
              @keypress="onlyNumberDot"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model.number="dataComputed.monthly_amount"
              :label="t('groups.monthly_charge_amount')"
              dense
              outlined
              prefix="$"
              :readonly="option===2"
              :rules="[required, regexValidator(dataComputed.monthly_amount, '^[0-9]{0,9}(\.[0-9]{0,2})?$', 'Incorrect format'), minNumber(dataComputed.monthly_amount, 0)]"
              @input="e => {
                if (!charUnique(e, '.')) {
                  dataComputed.monthly_amount = 0
                }
              }"
              @keypress="onlyNumberDot"
            />
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              v-model.number="dataComputed.order_reminder_minutes"
              :label="t('groups.order_reminder_minutes')"
              dense
              outlined
              :readonly="option===2"
              :rules="[integerValidator, between(dataComputed.order_reminder_minutes, 0, 360)]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
            <v-select
              v-model="dataComputed.status"
              dense
              outlined
              :label="t('status.status')"
              :items="computedStatusOptions"
              item-text="text"
              item-value="value"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="dataComputed.price_plan"
              dense
              outlined
              :label="t('groups.price_plan')"
              :items="pricePlanOptions"
              item-text="text"
              item-value="value"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
              @change="onSelectPricePlan"
            ></v-select>
          </v-col>

          <v-col
            v-if="dataComputed.price_plan === 'M'"
            cols="12"
            md="3"
          >
            <v-text-field
              v-model.number="dataComputed.min_orders_amount"
              :label="t('groups.min_orders_amount')"
              dense
              outlined
              prefix="$"
              :readonly="option===2"
              :rules="[required, regexValidator(dataComputed.min_orders_amount, '^[0-9]{0,9}(\.?[0-9]{0,2})?$', 'Incorrect format')]"
              @input="e => {
                if (!charUnique(e, '.')) {
                  dataComputed.min_orders_amount = 0
                }
              }"
              @keypress="onlyNumberDot"
            ></v-text-field>
          </v-col>

          <v-col
            v-if="dataComputed.price_plan === 'M'"
            cols="12"
            md="1"
            class="text-center align-center"
            align-self="center"
          >
            <p>{{ t('filter_by') }}</p>
          </v-col>

          <v-col
            v-if="dataComputed.price_plan === 'M'"
            cols="12"
            md="2"
          >
            <v-select
              v-model="dataComputed.min_orders_filter"
              dense
              outlined
              :label="t('groups.min_orders_filter')"
              :items="minOrdersFilterOptions"
              item-text="text"
              item-value="value"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            ></v-select>
          </v-col>

          <v-col
            v-if="dataComputed.price_plan === 'R'"
            cols="12"
            sm="12"
            md="4"
          >
            <v-text-field
              v-model="computedPriceRange"
              :label="t('groups.price_range')"
              dense
              outlined
              readonly
              :rules="[required]"
              :append-icon="option !== 2 ? icons.mdiPencilOutline : icons.mdiEyeOutline"
              @click:append="isModalOpen = true"
              @click="isModalOpen = true"
            />
          </v-col>

          <v-col
            cols="12"
            md="12"
          >
            <v-textarea
              v-model="dataComputed.notes"
              :label="t('groups.notes')"
              rows="4"
              dense
              outlined
              :readonly="option===2"
            />
          </v-col>

          <options-buttom-tab
            :tab-number="tab"
            :is-mode-save-edit="option !== 2"
            :info-required="true"
            :no-cancel="option === 3"
            :no-arrow="true"
            @onCancel="onCancelButton"
            @onSave="onSaveAction"
          >
          </options-buttom-tab>
        </v-row>
      </v-form>
    </v-card-text>

    <v-dialog
      v-model="isModalOpen"
      max-width="850px"
    >
      <v-card
        class="pa-3"
        rounded="10"
        style="width: 100%"
      >
        <v-form ref="formDialog">
          <v-app-bar
            flat
            color="rgba(0, 0, 0, 0)"
            dense
            class="px-4"
          >
            <v-toolbar-title class="text-h6 white--text pl-0">
              {{ t('groups.hour_price_range') }}
            </v-toolbar-title>
          </v-app-bar>

          <v-card-text class="pa-0 mt-5 mb-7 justify-center d-flex flex-column">
            <v-row class="justify-center align-center">
              <v-col
                v-for="(rangeInput, index) in rangeInputs"
                :key="index"
                cols="12"
                md="10"
                class="py-0"
              >
                <v-row
                  justify="start"
                  align="center"
                  class="mb-1"
                >
                  <v-col
                    cols="12"
                    md="2"
                    class="text-end white--text text-subtitle-1"
                  >
                    {{ t('groups.from') }} {{ rangeInput.from }}
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-select
                      v-model="rangeInput.operator"
                      dense
                      outlined
                      :items="operatorOptions"
                      item-text="text"
                      item-value="value"
                      hide-details
                      :append-icon="option===2 ? null : icons.mdiMenuDown"
                      :readonly="option===2"
                      :rules="[required]"
                    />
                  </v-col>
                  <v-col
                    v-if="rangeInput.operator == 'to'"
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      v-model.number="rangeInput.to"
                      v-mask="'####'"
                      dense
                      outlined
                      :class="{ 'mb-2': index > 0 }"
                      hide-details
                      :readonly="option===2"
                      :rules="[required, minNumber(rangeInput.to, rangeInput.from + 1)]"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    v-if="rangeInput.operator == 'to'"
                    cols="12"
                    md="2"
                    class="white--text text-subtitle-1"
                  >
                    {{ t('groups.hours') }}
                  </v-col>

                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      v-model.number="rangeInput.price"
                      prefix="$"
                      dense
                      outlined
                      :class="{ 'mb-2': index > 0 }"
                      hide-details
                      :readonly="option===2"
                      :rules="[required, regexValidator(rangeInput.price, '^[0-9]{0,9}(\.?[0-9]{0,2})?$', 'Incorrect format')]"
                      @input="e => {
                        if (!charUnique(e, '.')) {
                          rangeInput.price = null
                        }
                      }"
                      @keypress="onlyNumberDot"
                      @keydown.enter.prevent="addRange"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="1"
                  >
                    <v-icon
                      v-if="option !== 2 && index === rangeInputs.length-1 && index > 0"
                      @click="deleteRange(index)"
                    >
                      {{ icons.mdiClose }}
                    </v-icon>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                v-show="option !== 2 && rangeInputs.every(e => e.operator === 'to')"
                cols="12"
                md="10"
                class="py-0"
              >
                <p
                  id="addBtnForm"
                  class="text-body-1 ma-0 btn-add"
                >
                  + {{ t('groups.add_range') }}
                </p>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="d-flex flex-column justify-center py-3">
            <!-- <p class="text-xs text-center mb-3">
              {{ t('notify_players.message') }}
            </p> -->
            <div class="d-flex justify-space-between">
              <v-btn
                v-if="option!==2"
                class="text-xs"
                color="secondary"
                dark
                width="30%"
                rounded
                @click="onAcceptRange"
              >
                {{ t('tooltip.accept') }}
              </v-btn>

              <v-btn
                class="text-xs"
                color="lightGray"
                dark
                width="30%"
                rounded
                outlined
                @click="onCloseRange"
              >
                {{ t('tooltip.close') }}
              </v-btn>
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable arrow-body-style */
import { mdiCamera, mdiMenuDown, mdiEyeOutline, mdiPencilOutline, mdiClose } from '@mdi/js'
import { ref, computed, watch, onMounted, onBeforeMount } from '@vue/composition-api'
import { required, emailValidator, between, integerValidator, minNumber, regexValidator, charUnique } from '@core/utils/validation'
import { info } from '@core/utils/toasted'
import { useUtils } from '@core/libs/i18n'
import { avatarText } from '@core/utils/filter'
import { onlyNumberDot } from '@core/utils/functionsHelpers'
import usePhone from '@core/utils/usePhone'
import useCountry from '@core/utils/useCountry'
import parsePhoneNumber from 'libphonenumber-js'

import Vue from 'vue'
import { VueMaskDirective } from 'v-mask'

import { getFacilities } from '@api'
import OptionsButtomTab from '@/views/components/options-buttom-tab/OptionsButtomTab.vue'

export default {
  components: {
    OptionsButtomTab,
  },
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const { getCountryCode } = useCountry()
    const { phoneCodeOptions, initPhoneConfig } = usePhone()
    const form = ref(null)
    const dataComputed = computed(() => props.dataParams)
    const urlImage = ref(null)
    const imageData = ref(null)
    const facilitiesOptions = ref([])
    const pricePlanOptions = computed(() => [
      { text: t('groups.fixed_minimum_amount'), value: 'M' },
      { text: t('groups.only_hourly_price'), value: 'H' },
      { text: t('groups.price_hour_range'), value: 'R' },
    ])
    const formDialog = ref(null)
    const computedStatusOptions = computed(() => [
      { text: t('status.active'), value: 'A' },
      { text: t('status.inactive'), value: 'I' },
    ])
    const isModalOpen = ref(false)
    const rangeInputs = ref([
      { from: 1, to: null, price: null, operator: 'to' },
    ])
    const computedPriceRange = computed(() => rangeInputs.value.filter(e => e.price).map(e => `${e.from}-${e.operator === 'to' ? e.to : t('groups.up')} hours`).join(', '))
    const computedMsgClose = computed(() => t('status.inactive'))
    const initCode = ref('')
    const phoneCode = ref('')
    const phoneNumber = ref('')

    const operatorOptions = computed(() => [
      { text: t('groups.to'), value: 'to' },
      { text: t('groups.up'), value: 'up' },
    ])

    const billingCycleOptions = computed(() => [
      { text: t('billing_cycle_types.immediately'), value: 'A' },
      { text: t('billing_cycle_types.daily'), value: 'D' },
      { text: t('billing_cycle_types.weekly'), value: 'W' },
      { text: t('billing_cycle_types.monthly'), value: 'M' },
      { text: t('billing_cycle_types.manually'), value: 'N' },
    ])

    const minOrdersFilterOptions = computed(() => [
      { text: t('groups.group'), value: 'G' },
      { text: t('facilities.facility'), value: 'F' },
    ])

    const cleanImage = () => {
      imageData.value = null
      urlImage.value = null
    }

    const onFileSelected = e => {
      try {
        cleanImage()
        if (e && e.size >= 262144) info('Max size of 256K!')
        const type = e ? e.type.split('/').pop() : null
        if (e && (type === 'jpg' || type === 'png') && e.size < 262144) {
          imageData.value = e
          if (e) {
            urlImage.value = URL.createObjectURL(imageData.value)
          }
        } else {
          cleanImage()
        }
      } catch (err) {
        console.error('error', err)
      }
    }

    const onUpdateAvatar = async () => {
      if (imageData.value) {
        emit('updateAvatar', imageData.value)
      } else {
        info('Seleccione una imagen!')
      }
    }

    const onCancelButton = () => {
      cleanImage()
      form.value.reset()
    }

    const changeTab = tab => {
      if (!dataComputed.value.logo && !imageData.value) {
        info('Seleccione una imagen!')
      }

      if (form.value.validate() && (dataComputed.value.logo || imageData.value)) {
        if (imageData.value) dataComputed.value.imageData = imageData.value
        emit('changeTab', tab, true, dataComputed.value)
      }
    }

    const onSaveAction = () => {
      if (form.value.validate()) {
        if (dataComputed.value.price_plan === 'R' && !rangeInputs.value.some(e => e.operator === 'up' && e.price)) {
          info('You must complete the range price!')

          return
        }

        dataComputed.value.price_plan_range = rangeInputs.value.filter(e => e.price).map(e => {
          return {
            range: [e.from, e.operator === 'to' ? e.to : 1000000],
            hour_price: e.price,
            operator: e.operator,
          }
        })

        // dataComputed.value.monthly_amount = parseFloat(dataComputed.value.monthly_amount.replaceAll('$', '').replaceAll(',', ''))
        // dataComputed.value.min_orders_amount = parseFloat(dataComputed.value.min_orders_amount.replaceAll('$', '').replaceAll(',', ''))

        emit('save', dataComputed.value, imageData.value)
      }
    }

    const validatePhone = e => {
      if (phoneCode.value && e) {
        const phoneNumberJS = parsePhoneNumber(e, phoneCode.value)
        if (phoneNumberJS) {
          if (phoneNumberJS.isValid()) {
            dataComputed.value.phone = phoneNumberJS.number

            return true
          }
        }
      }

      return 'Invalid number'
    }

    const onSelectPricePlan = plan => {
      if (plan === 'M') rangeInputs.value = [{ from: 1, to: null, price: null, operator: 'to' }]
    }

    const addRange = () => {
      if (formDialog.value.validate() && rangeInputs.value.length < 30) {
        rangeInputs.value.push({
          from: rangeInputs.value[rangeInputs.value.length - 1].to + 1,
          to: null,
          price: null,
          operator: 'to',
        })
      }
    }

    const deleteRange = index => {
      rangeInputs.value.splice(index, 1)
    }

    const onCloseRange = () => {
      isModalOpen.value = false
    }

    const onAcceptRange = () => {
      if (rangeInputs.value.some(e => e.operator === 'up' && e.price)) isModalOpen.value = false
      else info('You must complete the range price!')
    }

    watch([isModalOpen], () => {
      if (isModalOpen.value) {
        setTimeout(() => {
          const btn = document.getElementById('addBtnForm')
          btn.addEventListener('click', addRange)
        }, 200)
      }
    })

    onBeforeMount(() => {
      Vue.directive('mask', VueMaskDirective)
    })

    onMounted(async () => {
      const response = await getFacilities()
      facilitiesOptions.value = response.data
      if (props.option === 1) {
        const countryCode = getCountryCode()
        if (countryCode) {
          const codeFinded = phoneCodeOptions.value.find(e => e.value === countryCode)
          if (codeFinded) initCode.value = countryCode
          else initCode.value = 'US'
        }
        phoneCode.value = initCode.value
      }

      if (props.option !== 1) {
        if (props.dataParams.price_plan === 'R') {
          rangeInputs.value = props.dataParams.price_plan_range.map(e => {
            return {
              from: e.range[0],
              to: e.range[1],
              price: e.hour_price,
              operator: e.operator,
            }
          })
        }

        setTimeout(() => {
          if (props.dataParams.phone) {
            const config = initPhoneConfig(props.dataParams.phone)
            phoneCode.value = config.code
            phoneNumber.value = config.phone_number
          }
        }, 300)
      }
    })

    return {
      // data
      form,
      urlImage,
      imageData,
      computedStatusOptions,
      facilitiesOptions,
      pricePlanOptions,
      isModalOpen,
      rangeInputs,
      formDialog,
      phoneCode,
      phoneNumber,

      // computed
      dataComputed,
      phoneCodeOptions,
      billingCycleOptions,
      operatorOptions,
      minOrdersFilterOptions,
      computedPriceRange,
      computedMsgClose,

      // methods
      onCancelButton,
      changeTab,
      onSaveAction,
      avatarText,
      onFileSelected,
      cleanImage,
      onUpdateAvatar,
      deleteRange,
      onCloseRange,
      onAcceptRange,
      onSelectPricePlan,
      initPhoneConfig,

      info,

      // validations
      required,
      emailValidator,
      between,
      integerValidator,
      minNumber,
      validatePhone,
      onlyNumberDot,
      regexValidator,
      charUnique,

      // i18n
      t,

      // icons
      icons: {
        mdiCamera,
        mdiMenuDown,
        mdiEyeOutline,
        mdiPencilOutline,
        mdiClose,
      },
    }
  },
}
</script>
