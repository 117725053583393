<template>
  <v-card
    id="user-form"
    min-height="350"
  >
    <!-- actions -->
    <v-fab-transition>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            color="secondary"
            dark
            absolute
            small
            top
            right
            fab
            class="mb-4 me-3"
            v-on="on"
            @click="goBack"
          >
            <v-icon>
              {{ icons.mdiTableArrowLeft }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ t("tooltip.back_table") }}</span>
      </v-tooltip>
    </v-fab-transition>

    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
      color="secondary"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
        color="secondary"
        :disabled="tab.disabled"
      >
        <v-icon
          v-if="!tab.hide"
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ t(`facilities.${tab.title}`) }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <group-tab-general
          :data-params="groupData"
          :tab="tab"
          :option="option"
          @save="save"
          @updateAvatar="updateAvatar"
          @cancelAction="cancelAction"
        ></group-tab-general>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
/* eslint-disable import/no-cycle */
import {
  mdiAccountOutline,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiTableArrowLeft,
} from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { useRouter } from '@core/utils'
import { error, success, info } from '@core/utils/toasted'
import useSelectOptions from '@/@core/utils/useSelectOptions'
import {
  createGroup,
  editGroup,
  editGroupLogo,
  getGroupsById,
} from '@api'

// demos
import GroupTabGeneral from './GroupTabGeneral.vue'

export default {
  components: {
    GroupTabGeneral,
  },
  setup() {
    const { t } = useUtils()
    const { route, router } = useRouter()
    const {
      configOrganization,
      configFacility,
    } = useSelectOptions()

    const tab = ref(0)
    const option = ref(1)
    const pageData = ref()
    const filterData = ref()
    const dataId = ref(null)
    const groupData = ref({
      name: null,
      email: null,
      phone: null,
      logo: null,
      status: 'A',
      facilities_ids: [],
      billing_cycle: null,
      comission: 0,
      min_orders_amount: 0,
      min_orders_filter: 'G',
      monthly_amount: 0,
      order_reminder_minutes: 0,
      notes: null,
      price_plan: 'H',
      price_plan_range: [],
    })
    const imageLogo = ref(null)
    const postData = ref({
      name: null,
      email: null,
      phone: null,
      logo: null,
      status: 'A',
      facilities_ids: [],
      billing_cycle: null,
      comission: 0,
      min_orders_amount: 0,
      min_orders_filter: 'G',
      monthly_amount: 0,
      order_reminder_minutes: 0,
      notes: null,
      price_plan: 'H',
      price_plan_range: [],
    })
    const putData = ref({
      name: null,
      email: null,
      phone: null,
      full_address: null,
      latitude: 0,
      longitude: 0,
      schedule: [],
      facilities_ids: [],
      billing_cycle: null,
      comission: 0,
      min_orders_amount: 0,
      min_orders_filter: 'G',
      monthly_amount: 0,
      order_reminder_minutes: 0,
      notes: null,
      price_plan: 'H',
      price_plan_range: [],
    })

    const tabs = ref([
      {
        title: 'general',
        icon: mdiAccountOutline,
        disabled: false,
        hide: false,
      },
    ])

    const changeTab = (tabNumber, validForm, data) => {
      switch (tabNumber) {
        case 1:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[1].disabled = false
            imageLogo.value = data.imageData
          }
          break
        case 2:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[2].disabled = false
          }
          break
        default:
          break
      }

      tab.value = tabNumber
    }

    const updateAvatar = async image => {
      const formData = new FormData()
      if (image && dataId.value) {
        formData.append('file', image)
        const responseLogo = await editGroupLogo(formData, dataId.value)
        if (responseLogo.ok) {
          success(responseLogo.message)
        }
      }
    }

    const save = async (data, logo) => {
      imageLogo.value = logo
      let resp = null
      if (option.value === 1) {
        if (!imageLogo.value) {
          info('Seleccione una imagen!')

          return
        }
        if (data && imageLogo.value) {
          postData.value = Object.assign(postData.value, {
            name: data.name,
            email: data.email,
            phone: data.phone,
            logo: data.logo ? data.logo : null,
            status: data.status,
            facilities_ids: data.facilities_ids,
            billing_cycle: data.billing_cycle,
            comission: data.comission,
            min_orders_amount: data.min_orders_amount,
            min_orders_filter: data.min_orders_filter,
            monthly_amount: data.monthly_amount,
            order_reminder_minutes: data.order_reminder_minutes,
            notes: data.notes,
            price_plan: data.price_plan,
            price_plan_range: data.price_plan_range,
          })

          resp = await createGroup(postData.value)

          if (resp.ok) {
            dataId.value = resp.data.id
            await updateAvatar(imageLogo.value)
          }
        }
      }

      if (option.value === 3) {
        putData.value = Object.assign(putData.value, {
          name: data.name,
          email: data.email,
          phone: data.phone,
          status: data.status,
          facilities_ids: data.facilities_ids,
          billing_cycle: data.billing_cycle,
          comission: data.comission,
          min_orders_amount: data.min_orders_amount,
          min_orders_filter: data.min_orders_filter,
          monthly_amount: data.monthly_amount,
          order_reminder_minutes: data.order_reminder_minutes,
          notes: data.notes,
          price_plan: data.price_plan,
          price_plan_range: data.price_plan_range,
        })

        resp = await editGroup(putData.value, dataId.value)

        if (imageLogo.value) {
          await updateAvatar(imageLogo.value)
        }
      }

      if (!option.value) {
        router.go(-1)
      }

      if (resp.ok) {
        router.go(-1)
        success(resp.message)
      } else {
        error(resp.message.text)
      }
    }

    const cancelAction = () => {
      tab.value = 0
      tabs.value[1].disabled = true
      tabs.value[2].disabled = true
      groupData.value = {}
    }

    const goBack = () => {
      router.replace({
        name: 'views-group-list',
        params: {
          pageParams: pageData.value,
          filterParams: filterData.value,
        },
      })
    }

    watch([configOrganization, configFacility], () => {
      goBack()
    })

    onMounted(async () => {
      option.value = route.value.params.option || 2
      pageData.value = route.value.params.pageParams
      filterData.value = route.value.params.filterParams
      dataId.value = route.value.params.id

      if (dataId.value) {
        const resp = await getGroupsById(dataId.value)
        if (resp.ok) {
          groupData.value = resp.data
        } else goBack()
      } else option.value = 1

      if (option.value !== 1) {
        tabs.value.forEach(e => {
          e.disabled = false
        })
      }
    })

    return {
      tab,
      tabs,
      option,
      dataId,
      groupData,
      imageLogo,
      postData,
      putData,

      changeTab,
      save,
      cancelAction,
      updateAvatar,
      goBack,

      // i18n
      t,

      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiTableArrowLeft,
      },
    }
  },
}
</script>
